import {
  twilight,
  dark,
  atomDark,
  darcula,
  coy,
  oneDark,
  oneLight,
  prism,
  materialOceanic,
  materialLight,
  materialDark,
  tomorrow,
  vs,
  okaidia,
} from "react-syntax-highlighter/dist/esm/styles/prism/index.js";

// supported request methods
export const REQUEST_METHODS = [
  "get",
  "post",
  "put",
  "patch",
  "delete",
  "head",
  "options",
  "trace",
];

export const REQUEST_CONTENT_TYPES = {
  formData: "application/x-www-form-urlencoded",
  formDataWithFile: "multipart/form-data",
  json: "application/json",
};

export const ERROR_MESSAGES = {
  GENERIC_ERROR: "Sorry, I was unable to process your request.",
  SOMETHING_WRONG: "Something went wrong. Please try again later.",
  CONNECTION_FAILED: "Connection failed. Please try again later.",
};

export const PLUGIN_URL = "";
export const API_URL = import.meta.env?.VITE_API_URL || "";

export const SYNTAX_HIGHLIGHT_THEMES = [
  {
    name: "twilight",
    value: twilight,
  },
  {
    name: "dark",
    value: dark,
  },
  {
    name: "atomDark",
    value: atomDark,
  },
  {
    name: "darcula",
    value: darcula,
  },
  {
    name: "coy",
    value: coy,
  },
  {
    name: "oneDark",
    value: oneDark,
    isDefault: true,
  },
  {
    name: "oneLight",
    value: oneLight,
  },
  {
    name: "prism",
    value: prism,
  },
  {
    name: "materialOceanic",
    value: materialOceanic,
  },
  {
    name: "materialLight",
    value: materialLight,
  },
  {
    name: "materialDark",
    value: materialDark,
  },
  {
    name: "tomorrow",
    value: tomorrow,
  },
  {
    name: "vs",
    value: vs,
  },
  {
    name: "okaidia",
    value: okaidia,
  },
];


export const AUTH_METHODS = {
  API_KEY: "api-key-bearer",
  OAUTH: "oauth-oidc",
};