import { API_URL } from "../constants.js";
import { getNormalizedDomain } from "./api.js";

export async function callOpenAi(message, domain, headers, signal) {
  try {
    const params = {
      message,
    };

    // Get the auth token from localStorage
    const authToken = localStorage.getItem("authToken");

    // Prepare headers
    const requestHeaders = {
      "Content-Type": "application/json",
      ...headers,
    };

    // Add the auth token to headers if it exists
    if (authToken) {
      requestHeaders["X-Auth-Token"] = authToken;
    }

    try {
      const response = await fetch(
        `${getNormalizedDomain(domain) || API_URL}/chatbot/chat-stream`,
        {
          method: "POST",
          credentials: "include",
          headers: requestHeaders,
          body: JSON.stringify(params),
          signal,
        }
      );

      // Check for and store new auth token from response headers
      const newToken = response.headers.get("x-auth-token");
      if (newToken) {
        localStorage.setItem("authToken", newToken);
      }

      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted');
        throw error;
      }
      throw new Error(error);
    }
  } catch (err) {
    return null;
  }
}
