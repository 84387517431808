import { useState } from "react";
import PropTypes from "prop-types";
import { AUTH_METHODS } from "../../constants";
import { verifyApiKey } from "../../services/chat-configurations";
import "./styles.scss";
import { Oval } from "react-loader-spinner";
import { RightArrowIcon } from "../../assets/svgs";

export const AuthOverlay = ({
  name,
  method,
  domain,
  onAuthSuccess,
  authorizationUrl,
  isAuthVerificationLoading,
}) => {
  return (
    <div className="auth-overlay-wrapper">
      {method === AUTH_METHODS.API_KEY && (
        <ApiKeyOverlay domain={domain} onAuthSuccess={onAuthSuccess} />
      )}
      {method === AUTH_METHODS.OAUTH && (
        <OAuthOverlay
          authorizationUrl={authorizationUrl}
          isAuthVerificationLoading={isAuthVerificationLoading}
          name={name}
          domain={domain}
        />
      )}
    </div>
  );
};

const ApiKeyOverlay = ({ domain, onAuthSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleApiKeySubmit = async () => {
    if (inputValue.trim() === "") {
      setErrorMessage("Please enter your authentication token");
      return;
    }
    try {
      setIsLoading(true);
      const response = await verifyApiKey(domain, inputValue);
      if (response.success) {
        onAuthSuccess();
      } else {
        setErrorMessage("Invalid Auth token. Please check and re-enter your credentials.");
      }
    } catch (error) {
      setErrorMessage("Invalid Auth token. Please check and re-enter your credentials.");
      console.error("Error saving API key", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApiKeySubmit();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage(""); // Reset error message when input changes
  };

  return (
    <div className="api-key-overlay-wrapper">
      <div className="api-key-overlay">
        <p>
          Please provide your unique authentication token to chat with your
          agent.
        </p>
        <div className="input-wrapper">
          <input
            type="password"
            placeholder="Enter your OAuth token here"
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            value={inputValue}
            disabled={isLoading}
          />
          <button
            className="submit-button"
            onClick={handleApiKeySubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Oval
                color="#fff"
                height={20}
                width={20}
                radius={10}
                strokeWidth={3}
                strokeWidthSecondary={3}
              />
            ) : (
              <RightArrowIcon />
            )}
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

const OAuthOverlay = ({
  authorizationUrl,
  isAuthVerificationLoading,
  name,
  domain,
}) => {
  const handleSignIn = () => {
    // open link in new tab, so that when user signs in this window gets focus
    window.open(authorizationUrl, "_blank");
  };
  return (
    <div className="oauth-overlay-wrapper">
      <div className="oauth-overlay">
        <p>
          Authorize Access to {name || "your agent"}. This will allow you to use
          our AI chatbot service securely.
        </p>
        <button onClick={handleSignIn} disabled={isAuthVerificationLoading}>
          {isAuthVerificationLoading ? (
            <Oval
              color="#fff"
              height={20}
              width={20}
              radius={10}
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <>
              <span>Sign in with </span>
              <span className="domain-text">{domain}</span>
              <RightArrowIcon />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

AuthOverlay.propTypes = {
  name: PropTypes.string,
  domain: PropTypes.string,
  onAuthSuccess: PropTypes.func,
  authorizationUrl: PropTypes.string,
  method: PropTypes.string.isRequired,
  isAuthVerificationLoading: PropTypes.bool,
};

ApiKeyOverlay.propTypes = {
  domain: PropTypes.string,
  onAuthSuccess: PropTypes.func,
};

OAuthOverlay.propTypes = {
  authorizationUrl: PropTypes.string,
  isAuthVerificationLoading: PropTypes.bool,
  name: PropTypes.string,
  domain: PropTypes.string,
};
