/* eslint-disable react/prop-types */
export const ReloadIcon = () => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="3"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="flex-shrink-0 icon-xs"
    height="0.85em"
    width="0.85em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="1 4 1 10 7 10"></polyline>
    <polyline points="23 20 23 14 17 14"></polyline>
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
  </svg>
);

export const ExpandIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 10L21 3M21 3H16.5M21 3V7.5M10 14L3 21M3 21H7.5M3 21L3 16.5"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
      fill="#0F0F0F"
    />
  </svg>
);

export const DownArrowIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
      fill={color || "#fff"}
    />
  </svg>
);

export const ChatIcon = ({ color }) => (
  <svg
    fill={color || "#fff"}
    height="28"
    width="28"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    enableBackground="new 0 0 50 50"
  >
    <path
      d="M15.526,33.729c0.068,0.042,0.084,0.07,0.096,0.135c0.094,0.511-0.497,1.623-0.96,2.229
c-0.209,0.274-0.263,0.637-0.142,0.959s0.398,0.561,0.736,0.63c0.278,0.056,0.544,0.081,0.798,0.081
c1.558,0,2.649-0.962,3.341-1.571c0.148-0.131,0.339-0.3,0.465-0.39c1.468,0.337,3.729,0.604,5.163,0.604
c0.798,0,1.597-0.057,2.384-0.156c1.198,0.388,2.5,0.608,3.865,0.608c0.947,0,2.359-0.161,3.367-0.378
c0.053,0.046,0.108,0.098,0.169,0.151c0.479,0.423,1.283,1.132,2.45,1.132c0.19,0,0.389-0.019,0.598-0.061
c0.337-0.069,0.614-0.307,0.736-0.629c0.121-0.322,0.067-0.686-0.142-0.959c-0.283-0.373-0.542-0.897-0.575-1.149
c2.445-1.558,3.902-4.041,3.902-6.663c0-1.615-0.563-3.122-1.522-4.412c-0.13-6.704-6.908-12.127-15.233-12.127
c-8.406,0-15.245,5.527-15.245,12.321C9.778,27.889,11.927,31.494,15.526,33.729z M36.73,33.322
c-0.446,0.277-0.731,0.695-0.824,1.208c-0.023,0.126-0.033,0.253-0.031,0.38c-0.31-0.253-0.664-0.477-1.115-0.477
c-0.081,0-0.231,0.019-0.311,0.038c-0.847,0.209-2.302,0.386-3.177,0.386c-4.69,0-8.506-2.94-8.506-6.556
c0-3.614,3.815-6.555,8.506-6.555s8.506,2.94,8.506,6.555C39.778,30.289,38.667,32.119,36.73,33.322z M25.023,13.763
c6.232,0,11.458,3.375,12.862,7.9c-1.807-1.196-4.108-1.916-6.613-1.916c-5.793,0-10.506,3.838-10.506,8.555
c0,2.363,1.183,4.506,3.092,6.055c-1.252-0.092-2.744-0.304-3.715-0.544c-0.844-0.21-1.494,0.369-2.071,0.878
c-0.239,0.211-0.465,0.403-0.688,0.564c0.198-0.563,0.31-1.174,0.205-1.749c-0.114-0.627-0.463-1.138-1.009-1.477
c-3.007-1.867-4.803-4.838-4.803-7.946C11.778,18.393,17.72,13.763,25.023,13.763z"
    />
  </svg>
);

export const SendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.33 3.66996C20.1408 3.48213 19.9035 3.35008 19.6442 3.28833C19.3849 3.22659 19.1135 3.23753 18.86 3.31996L4.23 8.19996C3.95867 8.28593 3.71891 8.45039 3.54099 8.67255C3.36307 8.89471 3.25498 9.16462 3.23037 9.44818C3.20576 9.73174 3.26573 10.0162 3.40271 10.2657C3.5397 10.5152 3.74754 10.7185 4 10.85L10.07 13.85L13.07 19.94C13.1906 20.1783 13.3751 20.3785 13.6029 20.518C13.8307 20.6575 14.0929 20.7309 14.36 20.73H14.46C14.7461 20.7089 15.0192 20.6023 15.2439 20.4239C15.4686 20.2456 15.6345 20.0038 15.72 19.73L20.67 5.13996C20.7584 4.88789 20.7734 4.6159 20.7132 4.35565C20.653 4.09541 20.5201 3.85762 20.33 3.66996ZM4.85 9.57996L17.62 5.31996L10.53 12.41L4.85 9.57996ZM14.43 19.15L11.59 13.47L18.68 6.37996L14.43 19.15Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export const SmythLogo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7607 16.6788L11.7336 10.9913C11.7066 6.84852 13.9213 3.00711 17.517 0.942341L17.8995 0.709761C19.1707 -0.0282536 20.7294 -0.0282536 22.0013 0.709761L32.1592 6.575C30.4638 5.64536 28.3858 5.67244 26.7043 6.64304L18.5834 11.3322L13.6068 14.203L13.1146 14.4904C13.1007 14.4904 13.1007 14.5043 13.0875 14.5043C12.3085 14.9826 11.8163 15.7894 11.7614 16.6781"
      fill="#2AAD8E"
    />
    <path
      d="M37.7736 23.1565V25.0706C37.7736 28.215 36.0921 31.1275 33.3712 32.7132L22.4336 39.0568C26.0292 36.992 28.244 33.1506 28.2169 29.0079L28.1898 23.3204V23.2794C28.2037 23.2385 28.2037 23.1836 28.2037 23.1426V16.8261C28.2037 15.8694 27.6976 14.98 26.8638 14.4746L28.2037 15.2536L35.7234 19.6011C36.9946 20.3391 37.7743 21.693 37.7743 23.1558L37.7736 23.1565Z"
      fill="#45C9A9"
    />
    <path
      d="M28.2166 29.0091C28.2437 33.1518 26.0289 36.9932 22.4333 39.058L22.0507 39.2905C20.7795 40.0286 19.2209 40.0286 17.9489 39.2905L7.79102 33.4253C9.48642 34.355 11.5644 34.3279 13.2459 33.3573L21.3669 28.6682L26.3434 25.7973L26.8357 25.5099C26.8495 25.5099 26.8495 25.496 26.8627 25.496C27.6417 25.0177 28.134 24.2109 28.1888 23.3223L28.2159 29.0097L28.2166 29.0091Z"
      fill="#2AAD8E"
    />
    <path
      d="M21.367 28.6672L13.2461 33.3564C11.5645 34.327 9.48658 34.3547 7.79118 33.4244C7.75022 33.3973 7.70926 33.3834 7.6683 33.3564L2.22656 30.2127L11.7971 24.7168L13.1371 25.4958C13.151 25.4958 13.151 25.5097 13.1642 25.5097L18.633 28.6679C19.4807 29.1602 20.52 29.1602 21.367 28.6679V28.6672Z"
      fill="#13947D"
    />
    <path
      d="M13.1361 25.4962L11.7962 24.7173L4.27649 20.3697C3.00526 19.6317 2.22559 18.2779 2.22559 16.815V14.9009C2.22559 11.7565 3.90712 8.84406 6.62797 7.25834L17.5663 0.914062C13.9706 2.97883 11.7559 6.82026 11.783 10.963L11.81 16.6505V16.6915C11.7962 16.7324 11.7962 16.7873 11.7962 16.8282V23.1447C11.7962 24.1014 12.3023 24.9901 13.1361 25.4962Z"
      fill="#45C9A9"
    />
    <path
      d="M37.7733 9.7586L28.2027 15.2545L26.8628 14.4755C26.8489 14.4755 26.8489 14.4616 26.8357 14.4616L21.3669 11.3034C20.5192 10.8111 19.4799 10.8111 18.6328 11.3034L26.7538 6.61424C28.4353 5.64364 30.5133 5.61656 32.2087 6.5462C32.2497 6.57328 32.2906 6.58716 32.3316 6.61424L37.7733 9.7586Z"
      fill="#13947D"
    />
  </svg>
);

export const AttachmentIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547M14.429 6.88674L7.00403 13.8812C6.67583 14.1907 6.49144 14.6106 6.49144 15.0483C6.49144 15.4861 6.67583 15.9059 7.00403 16.2154C7.33224 16.525 7.77738 16.6989 8.24154 16.6989C8.70569 16.6989 9.15083 16.525 9.47904 16.2154L13.502 12.4254M8.55638 7.75692L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L16.5 9.601"
      stroke="#000000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrowIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 8.5H15.5M15.5 8.5L8.5 1.5M15.5 8.5L8.5 15.5"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path fill="#fff" d="M6 7v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z"/> </g> </svg>
);
