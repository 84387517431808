/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import "./../chatbox/styles.scss";
import { SYNTAX_HIGHLIGHT_THEMES } from "../../constants";

export const SystemMessage = ({
  message,
  errorMessage,
  botBubbleClass,
  colors,
  syntaxHighlightTheme,
}) => {
  if (errorMessage && message?.id === message.id) {
    return null;
  }

  if (!message?.content) {
    return null;
  }

  return (
    <>
      <div className="chat-message system" key={message.id}>
        <div
          className={`chat-content ${botBubbleClass}`}
          style={{
            color: colors?.botBubbleColors?.textColor,
            background: `linear-gradient(to right, ${colors?.botBubbleColors?.backgroundColorStart}, ${colors?.botBubbleColors?.backgroundColorEnd})`,
          }}
        >
          <div className="chat-text" key={syntaxHighlightTheme}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                code(props) {
                  // eslint-disable-next-line no-unused-vars
                  const { children, className, node, ...rest } = props;
                  const match = /language-(\w+)/.exec(className || "");
                  return match ? (
                    <SyntaxHighlighter
                      {...rest}
                      key={syntaxHighlightTheme}
                      // wrapLongLines={true}
                      PreTag="div"
                      // eslint-disable-next-line react/no-children-prop
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                      style={
                        SYNTAX_HIGHLIGHT_THEMES.find(
                          (theme) => theme.name === syntaxHighlightTheme
                        )?.value ||
                        SYNTAX_HIGHLIGHT_THEMES.find((theme) => theme.isDefault)
                          ?.value
                      }
                    />
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {message?.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </>
  );
};

SystemMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  errorMessage: PropTypes.string,
  botBubbleClass: PropTypes.string,
};
