/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Typewriter from "../typewriter";
import { ERROR_MESSAGES } from "../../constants";
import { ReloadIcon } from "../../assets/svgs";
import "./styles.scss";

export const ErrorMessage = ({
  errorText,
  defaultMessage,
  setErrorMessage,
  handleUserInputSubmit,
  setCurrentMessage,
  setIsTyped,
}) => {
  return (
    <React.Fragment>
      <div className="chat-message system">
        <div className="chat-content">
          {
            <div className="text">
              <Typewriter
                message={{
                  ...defaultMessage,
                  content: errorText || ERROR_MESSAGES.GENERIC_ERROR,
                }}
                speed={10}
                setCurrentMessage={(text) => setCurrentMessage(text)}
                setIsTyped={(message) => setIsTyped(message)}
              />
              <button
                type="button"
                onClick={() => {
                  setErrorMessage("");
                  handleUserInputSubmit(false);
                }}
                className="regenerate-button"
              >
                <ReloadIcon />
                Retry
              </button>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

ErrorMessage.propTypes = {
  logo: PropTypes.string,
  pluginDomain: PropTypes.string,
  name: PropTypes.string,
  humanBubbleClass: PropTypes.string,
  botBubbleClass: PropTypes.string,
  introMessage: PropTypes.string,
  domain: PropTypes.string,
};
