/* eslint-disable no-undef */
import axios from "axios";
import { API_URL } from "../constants.js";

export const getPluginManifest = async (domain, headers) => {
  // remove forward slash from the end of the domain
  // domain = domain ? domain.replace(/\/$/, "") : null;

  // Normalize the domain to always include https protocol if not present
  const normalizedDomain =
    domain && !domain.startsWith("http://") && !domain.startsWith("https://")
      ? `https://${domain}`
      : domain;
  try {
    const { data } = await axios.get(
      `${normalizedDomain || API_URL}/chatbot/chat-configs`,
      {
        headers,
      }
    );
    return data;
  } catch {
    return domain;
  }
};

export const getNormalizedDomain = (domain) =>
  domain && !domain.startsWith("http://") && !domain.startsWith("https://")
    ? domain.includes("localhost") || domain.includes("localagent")
      ? `http://${domain}:3000`
      : `https://${domain}`
    : domain;
