import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Typewriter = ({ message, speed, setCurrentMessage, setIsTyped }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex < message?.content?.length) {
        setDisplayedText(
          (prevText) => prevText + message?.content?.[currentIndex]
        );
        setCurrentMessage((prevMessage) => {
          return {
            ...prevMessage,
            content: prevMessage.content + message?.content?.[currentIndex],
          };
        });
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        setIsTyped(message);
        clearInterval(timer);
      }
    }, speed);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.content, speed, currentIndex]);

  return (
    <div>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{displayedText}</ReactMarkdown>
    </div>
  );
};

// setprop types for the component
Typewriter.propTypes = {
  message: PropTypes.object.isRequired,
  speed: PropTypes.number.isRequired,
  setCurrentMessage: PropTypes.func.isRequired,
  setIsTyped: PropTypes.func.isRequired,
};

export default Typewriter;
