import ReactDOM from "react-dom/client";
import "./index.css";
import { ChatBox } from "./components/chatbox/index.jsx";

function renderChatBot(props) {
  ReactDOM.createRoot(document.getElementById("smyth-chatbot")).render(
    <ChatBox {...props} />
  );
}

window.ChatBot = {
  init: renderChatBot,
  createDiv: (() => {
    const div = document.createElement("div");
    div.id = "smyth-chatbot";
    document.body.appendChild(div);
  })(),
};

if (import.meta.env?.VITE_APP_ENV === "development") {
  window.ChatBot.init({
    icon: "./ink-logo.png",
    domain: import.meta.env.VITE_API_URL,
  });
}
