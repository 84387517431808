/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import "./../chatbox/styles.scss";

export const UserMessage = (props) => {
  const { id, content } = props.message;
  const { humanBubbleClass } = props;

  const renderContent = (_content) => {
    // split the content by new line
    const contentArray = _content.split("\n");
    // render the content with <br /> tag
    return contentArray.map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  }

  return (
    <div className="chat-message user" key={id}>
      <div
        className={`${"chat-content"} ${humanBubbleClass}`}
        style={{
          color: props.colors?.humanBubbleColors?.textColor,
          background: `linear-gradient(to right, ${props.colors?.humanBubbleColors?.backgroundColorStart}, ${props.colors?.humanBubbleColors?.backgroundColorEnd})`,
        }}
      >
        <div className="chat-text">{renderContent(content)}</div>
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  humanBubbleClass: PropTypes.string,
};
